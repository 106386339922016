.mainwrapper{
  margin-top: 10rem;
  height: fit-content;
  }

.Wrapper{
  margin-top: 15rem ;
}
  
  .parallaxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-image: url('../../../../assets/ServiceBG.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin: 2% 1% 2% 1%;
  }
  .redLine{
    height: 5px;
    width: 100px;
    background-color: red;

  }
  
  .contentBox{
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    background: rgba(0, 0, 0, 0.48);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(199, 199, 199, 0.3);
    height: 90%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .test{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: -100px;

  }
  
  .heading1{ 
      margin: 0;
      font-size: 4rem;
      font-family: 'Poppins';
      font-weight: 500;
      margin-top: -15px;
      color: black;
      text-align: center;
  }
  
  .test p{
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Poppins';
    font-weight: 400;
    width: 70%;
  }
  
  .servicebutton{
      font-size: 1.2rem;
      font-family: 'Poppins';
      background-color: rgb(115, 213, 255);
      color: white;
      border: 0px;
      width: fit-content;
      border-radius: 3px;
      padding: 5px;
      margin-bottom: 20px;
      padding-left: 1.3rem;
      padding-right: 1.3rem;
  } 
  
  .servicebutton:hover{
      cursor: pointer;
      background-color: white;
      color:rgb(115, 213, 255);
  }
  

.iconContainer{
  padding-top: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.compo{
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.deco{
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 19%;   /* changed this to have all icons inside the box*/
  /* width: 30%; */
}

.icon{
  z-index: 10;
  height: 70px;
  width: 70px;
  background-position: center;
  background-size:contain ;
  background-repeat: no-repeat;
}

.heading{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  font-size: 1.2rem;
  text-align: center;
}



@media (max-width:685px){
  .deco{

    padding-top: 1rem;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
  }
  .contentBox{
    height: fit-content;
  }
  .parallaxContainer {
    height: fit-content;
  }
  .contentBox{
    width: 100%;
    }
    .heading1{ 
      margin-top: -8px;

      font-size: 3rem;
    }
    .test p{
      width: 90%;
    }

}