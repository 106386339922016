*{
    margin: 0px;
  }
  
  .wrapper {
    background-color: white;
    width: 90%;
    max-width: 1500px;
  }
  
  
  .article {
    margin-top: 1rem;
    display: flex;
    align-self: center;
    width: 100%;
    height: fit-content;
    min-height: 500px;
    border-radius: 10px;
    margin-bottom: 5rem;
  }
  
  .article:nth-child(1) {
    background-color:  #E3E1D9;
  }
  .article:nth-child(2) {
    background-color:  #C7C8CC;
  }
  .article:nth-child(3) {
    background-color:  #E3E1D9;
  }
  .article:nth-child(4) {
    background-color:  #C7C8CC;
  }
  .article:nth-child(5) {
    background-color:  #E3E1D9;
  }
  .article:nth-child(6) {
    background-color:  #C7C8CC;
  }
  .article:nth-child(7) {
    background-color:  #E3E1D9;
    
  }
  /* .article:nth-child(7) img{
    background-size: contain;
  } */
  .article:nth-child(8) {
    background-color:  #C7C8CC;
  }
  .article:nth-child(9) {
    background-color:  #E3E1D9;
  }
  
  .left {
    flex-direction: row-reverse;
  }
  
  .right {
    flex-direction: row;
  }
  li{
    margin: 0.5rem;
  }
  .imageContainer {
    border-radius: 10px;
    width: 73%;
    margin: 1rem;
    height: auto;
    display: flex;
    align-items: center;
    border: 5px solid transparent;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .contentContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem;
    margin: 1rem;
    font-size: 1rem;
    text-align: justify;
    font-family: 'Poppins';
    
  }
  
  .service {
    position: relative;
    padding-top: 10vh;
  }
  
  .overlay {
    width: 100%;
    height: fit-content;
    text-align: center;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .overlay h1{
    margin: 0;
  }
  
  .overlayHeading {
    margin-top: -5px;
    font-size: 2rem;
    height: auto;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 40px ;
  }
  
  .redLine{
    background-color: red;
    height: 6px;
    width: 90px;
    margin-top: 1rem;
    
  }
  
  .overlayContent {
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 300;
    height: fit-content;
    text-align: justify;
    width: 90%;
    
  }
  
  .headline {
    font-weight: 400;
    font-size: 3em;
    font-family: 'Poppins';
  }
  
  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-attachment: fixed;
  }
  
  .contentContainer p{
    font-family: "Poppins";
    font-weight: 200;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  @media (max-width: 810px) {
    .wrapper{
      width: 95%;
     }
  
  .article{
    width: fit-content;
    height: fit-content;
  }
  
    .left {
      flex-direction: column; 
    }
  
    .right {
      flex-direction: column; 
    }
  
    .imageContainer {
      border-radius: 10px;
      width: 90%;
      margin: 1rem;
      height: 400px;
      display: flex;
      align-items: center;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  
    .contentContainer{
      text-align: left;
      min-width: fit-content;
      padding: 0;
    }
  
    .overlay {
      width: 100%;
      height: fit-content;
      text-align: center;
      background-color: #f0f0f0;
      padding-bottom: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .overlayContent{
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 1rem;
      width: 90%;
      text-align: left;
    }
  
    .overlayHeading {
      height: auto;
      font-family: 'Poppins';
      font-weight: 400;
      margin: 0;
      padding: 1%;
    }
  
  }
  
  
  @media (min-width: 815px)  {
     
    .article{
     height: fit-content;
     width: fit-content;
     display: flex;
    }
   
   .overlayContent{
     text-align: justify;
     font-size: 1rem;
     width: 90%;
   }
   .contentContainer{
     text-align: left;
     width: 100%;
     padding: 2rem;
     font-size: 1rem;
   }
   .wrapper{
    width: 95%;
   }
   
   }
  
   @media (min-width: 650px) and (max-width:800px){
    .imageContainer{
      width: 95%;
    }
   }