.mainContainer{
    margin-left: 6rem;
    z-index: 1;
    color: white;
    width: 40%;
}


.heading{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 5rem;
    padding: 1px;
    line-height: 1;
}


.content{
    margin-left: 8px;
    margin-top: 25px;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 2rem;
    line-height: 1;
}


.line{
    border-top: 7px solid #FF0505;
    width: 90px;
    margin-left: 3px;
}

@media only screen and (max-width:660px) {
    .mainContainer{
        margin-left: 2rem;
        width: 100%;
    }
    .heading{
        font-size: 70px;
    }
    
  }