.componentWrapper{
    width: 100%;
    margin-top: 18rem;
 
    }

.maincontainer{
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
} 
.TextBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 115px;
    background-color: white;
    width: 100%;
    height: fit-content;
    
    align-self: center;

}  
.redLine{
    height: 5px;
    width: 90px;
    background-color: red;
    margin-left: 2rem;
}
.heading{
    margin-top: -15px;
    color: black;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Poppins';
    align-self: center;
    text-align: center;
}
.content{
    font-size: 1rem;
    text-align: center;
    width: 65%;
    font-family: 'Poppins';
    margin-bottom: 35px;
    color: rgb(53, 53, 53);
    max-width: 1440px;


}
.mainprcontainer{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.productlog{
    justify-content: center;
    background-color: white;
    /* min-width: 1200px; */
    display: grid;
    /* grid-template-rows: 350px 350px ; */
    /* grid-template-columns: 25% 25% 25% 25% ; */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(350px, 1fr));

    width: 100%;
    height: 80%;
    gap: 3rem;
    max-width: 1440px;
}

@media (max-width:685px){
    .heading{
        margin-top: -10px;
        font-size: 3rem;
    }
    .content{
        width: 90%;
        text-align: center;
        font-size: 0.9rem;

    }
}


