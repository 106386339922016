.mainContainer{
    margin-top: 10rem;
    height: fit-content;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
}

.wrapper{
    height: fit-content;
    background-color: lightblue;
    width: 84%;
    display: flex;
}

.imageContainer{
    width: 50%;
    background-repeat: no-repeat;
    background-color: grey;
    /* background-color: #f0f0f0;
    background-size: contain;*/
    background-position: center;
    background-size: cover;
    background-image: url("../../../../assets/thirdtry.jpg");
}


@media (max-width: 1200px) {    
    .wrapper{
        width: 100%;
    }   
}


@media (max-width: 842px) {    
    .wrapper{
        flex-direction: column-reverse;
    }
    .imageContainer{
        background-image: url("../../../../assets/GroupPic.jpeg");
        width: 100%;
        height: 40vh;
    }

  
}