.container {
    overflow: hidden;
    margin-top: 2rem;
  }
  
  .scrollWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    animation: scroll 18s linear infinite;
    width: fit-content;
  }
  
  .image {
    width: 200px;
    height: 150px;
    margin: 30px;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-65%);
    }
  }
  
  @media screen and (max-width: 768px) {
    .image {
      width: 100px; 
      height: 100px; 
    }
  }