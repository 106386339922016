.values{
    display: flex;
    height: fit-content;
    padding-bottom: 2rem;
}
.icon{
    background-color:#F0F0F0;
    height: 70px;
    width: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 3rem 2rem 3rem 2rem;
  
}

.valueText{
    height: fit-content;
    width: calc(100% - 110px);
}

.heading{
    font-family: "Poppins";
    font-weight: 400;
    font-size: 28px;
}
.content{
    font-family: "Poppins";
    font-weight: 300;
    font-size: 15px;
    color: rgb(0, 0, 0);
    width: 90%;
}

@media (max-width: 842px) {    
    .values{
        flex-direction: column;
        align-items: center;
        padding-bottom: 0px;
        justify-content: center;
        text-align: center;
    }
    .icon{
        height: 10vh;
        width:10vh;
        margin: 2rem 0rem 1rem 0rem;


    }
    .valueText{
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        width: 100%;
    }
}