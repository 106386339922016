.mainContainer{
    background-color: transparent;
    border-top-left-radius:20px ;
    border-bottom-left-radius: 20px;
    height: 100%;
    width: 40%;

}
.mainImageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F6F6;
    height: calc(80% - 20px);
    border-top-left-radius: 20px;
    padding: 20px;
    padding-right: 1px;
}

.mainImage{
    height: 100%;
    width: 100%;
    background-color: white;
    position: relative;
    border-radius: 20px ;
}

.thumbContainer{
    background-color: #F6F6F6;
    height: 100%;
    max-height: 100px;
    padding-top: 2px;
    margin-left: 20px;
    overflow-y: hidden;
    overflow-x: scroll;

}

.thumbnails{
    width: fit-content;
    background-color: #F6F6F6;
    display: flex;
    flex-direction: row;
    margin-left: 8px;
    overflow-x: auto;
}
.media{
    height: 100px;
    width: 100px;
    margin-right: 20px;
    background-color: grey;
}
.media1{
    height: 100px;
    width: 100px;
    margin-right: 20px;
    background-color: red;
}
.media:hover{
    cursor: pointer;
}
.thumbContainer::-webkit-scrollbar {
    width: 10px; 
  }
  
  .thumbContainer::-webkit-scrollbar-thumb {
    background-color: #D9D9D9; 
    border-radius: 5px; 
  }
  
  .thumbContainer::-webkit-scrollbar-track {
    background-color: transparent; 
  }

  @media (max-width: 1000px) {
    .mainContainer{
        width: 100%;
        height: 50vh;
    
    }
    .mainContainer{
        background-color: white;

    }
    .thumbContainer{
        height: 100%;
    }
    .mainImageContainer{
        padding-right: 20px;

    }



}

