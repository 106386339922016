.mainwrapper{
    display: flex;
    justify-content: center;
}


.mainContainer {
    margin-top: 50px;
    margin-bottom:67px;

    background-color: #362FD9;
    background-image: url("../../../../assets/y1.jpg");
    background-position: center;
    background-size:cover ;
    height: 30vh;
    min-height: 250px;
    width: 84%;
    /* min-width: 1000px; */
    align-self: center;
    color: white;
}
.overlay{
    background-color: rgba(0, 0, 0, 0.7);
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;

}
.wrappers{
    margin: 1px;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.border{
    padding-top: 3px;
    height: 90%;
    width: 250px;
    /* border: 2px dotted ;
    border-radius: 10px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='white' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='42' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 6px;}

.imageContainer{
    margin-top: 0.5rem;
    height: 50%;
    width: 80%;
    background-position: center;
    background-size:contain ;
    background-repeat: no-repeat;
}
.valueContainer{
    text-align: center;
}
.textvalue{
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 200;
}
.number{
    font-size: 3em;
    font-family: "Poppins";
    font-weight: 600;
}
.seprator{
    height: 40%;
    background-color: white;
    width: 10px;
    align-self:center ;
    margin-left: 5px;
    margin-right: 5px;

}

@media (max-width: 1200px) {
    .mainwrapper{
        width: 100%;
    
        align-items: center;
    }   
    .mainContainer {
        width: 100%;
        height: fit-content;
        
    }
    .overlay{
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        
    }
    .wrappers{
        height: 250px;
        width: 40%;
        min-width: 345px;
    
    }
    .border{
        min-width: 160px;
    }
    .seprator{
        height: 0px;
        background-color: white;
        width: 0px;
        align-self:center ;
        margin-left: 0px;
        margin-right: 0px;
    
    }

    
}