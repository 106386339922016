.Wrapper{
    display: flex;
    justify-content: center;
    
}

.mainContainer{
    width: 90%;
    max-width: 90vw;
    margin-top: 10vh;

}
.headingWrapper{
    background-color: white;
    display: flex;
    justify-content: center;
    height: fit-content;
    width: 100%;
    padding-bottom: 2rem;
    padding-top: 2rem;
    background-color: #ffffff;
    opacity: 1;
    background: radial-gradient(circle, transparent 20%, #ffffff 20%, #ffffff 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #ffffff 20%, #ffffff 80%, transparent 80%, transparent) 10px 10px, linear-gradient(#dfdfdf 0.8px, transparent 0.8px) 0 -0.4px, linear-gradient(90deg, #dfdfdf 0.8px, #ffffff 0.8px) -0.4px 0;
    background-size: 20px 20px, 20px 20px, 10px 10px, 10px 10px;    
}

.redLine{
    background-color: red;
    width: 15%;
    height: 5px;
    margin-bottom: -4px;
}
.mainHeading{
    font-family: "Poppins";
    font-weight: 300;
    font-size: 3.5rem;
    text-align: center;
    opacity: 1;
    color: black;
    line-height: 1.2;
}
.head{
    display: flex;
    flex-direction: column;
}
.mainHeading1{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.mainHeading1 .redLine{
    align-self: center;
}

.mainHeading1 h6{
    margin-top: 1rem;
    padding: 3rem;
    font-size: 1rem;
    font-family: "Poppins";
    font-weight: 300;
    /* background-color: #EAD8C0; */

} 

.mainHeading1 p{
    font-family: "Poppins";
    font-weight: 300;
    font-size: 3.5rem;
    text-align: center;
    opacity: 1;
    color: black;
    line-height: 1.2;
}

.DataContainer{
    display: flex;
    background-color: #F2F2F2;
    height: fit-content;
    width: 100%;
    min-height: 500px;
}
.textBox{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 3rem;
}
.textBox h1{
    margin-top: 2rem;
    font-family: "Poppins";
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
}
.textBox p{
    width: 80%;
    font-size: 0.9rem;
    font-family: "Poppins";
    font-weight: 300;
}
.imageContainer{
    width: 50%;
    background-color: blue;
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 842px) {
    .mainContainer{
        width: 100%;
        max-width: 100vw;
        padding: 0px;
    margin: 0px;
    margin-top: 10vh;


    }
    .Wrapper{
        width: 100vw;
        justify-content: center;
        
    }
    .mainHeading{
       display: block;
       font-size: 3.2rem;
       width: 100vw;
        
    }
    .headingWrapper{
        background-color: #ffffff;
        opacity: 1;
        width: 100vw;
        background: radial-gradient(circle, transparent 20%, #ffffff 20%, #ffffff 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #ffffff 20%, #ffffff 80%, transparent 80%, transparent) 10px 10px, linear-gradient(#dfdfdf 0.8px, transparent 0.8px) 0 -0.4px, linear-gradient(90deg, #dfdfdf 0.8px, #ffffff 0.8px) -0.4px 0;
        background-size: 20px 20px, 20px 20px, 10px 10px, 10px 10px;
        
    }
    .redLine{
        padding-left: 1rem;
    }
    
    
}