.mainContainer{
    display: flex;
    height: fit-content;
    width: 100%;
    background-color: #F2F2F2;
    margin-bottom: 5rem;
}

.heading h1{
    font-family: "Poppins";
    font-weight: 400;
    margin-bottom: 1rem;
}

.redline{
    height: 6px;
    width: 110px;
    margin-top: 2rem;
    background-color: red;
}

.imageContainer{
    background-color: grey;
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: 100%;
    margin-bottom: 2rem;
    
}

.rightContainer{
    display: flex;
    height: 100%;
    width: 40%;
    flex-direction: column;
    padding-left: 2rem;
    padding-top: 1rem;
}

.leftContainer{
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 60%;
    font-family: "Poppins";
    font-weight: 200;
}
.leftContainer p {
    width: 90%; 
}

@media screen and (max-width: 842px) {
    .mainContainer{
        display: flex;
        flex-direction: column;
      
    }
  
    .rightContainer{
        display: flex;
        height: 100%;
        width: 90%;
        align-self: center;
        flex-direction: column;
        padding-left: 0rem;
        padding-top: 1rem;
    }
    .leftContainer{
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        width: 90%;
        font-family: "Poppins";
        font-weight: 200;
        align-self:center;
    }


}
