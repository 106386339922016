* {
    box-sizing: border-box;
  }
  
  body {
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
    font-family: 'Poppins';
    font-weight: 300;
  }
  
  .headingContainer{
    display: flex;
    justify-content: center;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }

  .headingContainer h1{
    margin-top: -8px;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 45px;
  }
  .headingContainer p{
    width: 60%;
    text-align: center;
  }
  .container {
    height: fit-content;
    width: 100%;
    margin-top: 200px;

  }
  
  .cards {
    margin-top: 100px;
    width: 100%;
    height: fit-content;
    margin-bottom: 200px;    
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .cards  .cards {
    height: fit-content;
  }
  
  .card {
    position: sticky;
    height: 70vh;
    width: 80vw;
    top: 3em;
    padding: 1.2em;
    padding-right: 12px;
    font-size: 35px;
    border-radius: 20px;
    display: flex;
    align-items: center; 
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  
  .cardContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%; 
    height: 70vh;

    
  }
  
  .cardImage {
    width: 50%; 
    height: 110%;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }
  
  .card:nth-child(1) {
    background-color:  #F0F0F0;
    transform: translateY(-10px);

  }
  
  .card:nth-child(2) {
    margin-top: 18vh;
    background-color: #E3E1D9;
    transform: translateY(30px);
  }
  
  .card:nth-child(3) {
    margin-top: 18vh;
    background-color: #C7C8CC;
    transform: translateY(80px);

  }
  
  .cardtitle {
    font-family: 'Poppins';
    font-weight: 300;
    text-align: left;
    padding-top: 1%;
    line-height: 100px;
  }
  
  .cardtitle h3{
    font-size: 3rem;
    font-family: 'Poppins';
    font-weight: 300;
    line-height: 4.5rem;
    line-height: 4rem;
  }
  
  .cardtext {
    font-size: 1rem;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;

  }
  .cardtext p{
    width: 90%;
  }
  .card:nth-child(2) .cardtext p {
    font-size: 0.9rem;
  }

  .popup{
    width: 40%;
    height: fit-content;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 200;
    font-family: "Poppins";
    margin: 1%;
    margin-top: 1rem;
    color: white;
    background-color: black;
    border-radius: 10px;
    border: 0px;
  }
  
  .popup:hover{
    cursor: pointer;

    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  @media (max-width:1200px) {
    .card {
      width: 95%;
    }

  }


  @media (max-width: 800px) {    
    .card {
      flex-direction: column-reverse;
      height: 80vh;
      padding: 0px;
      top: 2em;

    }
    .cardImage {
      width: calc(100% - 11px);
      margin: 10px;
      height: 50vh;
    }
    .cardContent {
      width: 100%;
    }
    .cardtitle h3{
      font-size: 1.6rem;
      line-height: 2rem;

    }
    .cardtext p{
      font-size: 0.5rem;
    }
    .card:nth-child(2) .cardtext p {
      font-size: 0.5rem;
    }

  }
  