.eachSlideEffect > div {
    display: flex;
    align-items: center;
    justify-content: left;
    background-size: cover;
    background-color: white;
    margin-top: 10vh;
    height: 84vh;
    

  }
  .Wrapper{
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;

  }
  .bg{
    background-position: center;
    background-size: contain;
  }

  .eachSlideEffect  span {
    position: absolute;
    margin-left: 5rem;
    padding: 20px;
    font-size: 20px;
    background: black;
    text-align: center;
    opacity: 0.7;
    height: 80%;
    width: 40vw;
    border-radius: 25px;
  }

  .mainContainer{
    /* padding-top: 58px; */
    width: 90%;
  }
  .line{
    border-top: 7px solid #FF0505;
    width: 90px;
    position: absolute;
    margin-bottom: -19px;
    z-index: 1;
}

.indicator {
  cursor: pointer;
  border-radius: 50%;
  padding: 6px;
  text-align: center;
  background-color: rgb(148, 148, 148);
  margin: 7px;
}
.indicator.active {
  color: #fff;
  background: #000000;
}
@media only screen and (max-width:1000px) {
  .mainContainer{
    width: 100%;
    /* height: 100vh; */
    padding: 0;
  }
  
  .eachSlideEffect  span {
    position: absolute;
    font-size: 20px;
    background: black;
    text-align: center;
    opacity: 0.7;
    height: 100%;
    width: 100%;
    border-radius: 0px;
    margin-left: 0;
    padding: 0px;


  }
  
  
}