.carousel {
  background-image: url("../../../../assets/Testimonials/testimonials.jpg");
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh; 
  overflow: hidden;
  position: relative;
  margin-top: 4rem;
}

.tcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line{
      background-color: red;
      height: 5px;
      width: 100px;   
}

.heading{
  margin: 0;
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
}

.content{
  font-size: 2.2rem;
  margin: 1rem;
  font-family: 'Poppins';
}

.testcard{
  background-color: white;
  min-height: 50%;
  width: 60%;
  color: black;
  opacity: 0.9;
  margin: 0.5rem;
  font-family: 'Poppins';
  padding-bottom: 1rem;
}

.slide {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.text {
  text-align: center;
  font-family: 'Poppins';
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  font-size: 19px;
}

.image {
  margin-top: 1rem; 
  margin-left: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.image img {
  width: 250px; 
  height: 120px;
}



@media (max-width: 768px) {
  .carousel {
    height: 60vh; 
  }

  .text{
      font-size: 0.7rem;
  }

  .image {
    margin-top: 5px; 
  }

  .image img {
    width: 100px; 
    height: auto;
  }
}
