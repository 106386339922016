
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

:root {
	--mainColor: #ffffff;
	--mainColorLight: #5767aa;
	--textColor: #000000;
}
.companylogo{
	height: 100%;
	width: 10%;
	background-image: url("../../../../assets/companyLogo.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 0rem;
	display: flex;
}


header {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10vh;
	background-color: var(--mainColor);
	color: var(--textColor);
	border-bottom: 1px solid rgb(223, 223, 223);
	gap: 40px;
	position: fixed;
	z-index: 11;
	width: 100%;
}

nav a {
	margin: 0 2rem;
	text-decoration: none;
	color: black;
	background-color: transparent;
	width: fit-content;
	align-self: center;
	display: inline-block;
	position: relative;
	text-align: center;
}

 a:after {    
	background: none repeat scroll 0 0 transparent;
	bottom: 2;
	content: "";
	display: block;
	height: 3px;
	left: 50%;
	position: absolute;
	background: red;
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	width: 0;
  }

.hov{
	cursor: pointer;

}
  .hov:hover:after { 
	width: 100%; 
	left: 0; 
  }



header .navBtn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	display: none;
	opacity: 0;
	font-size: 1.8rem;
}
.companyName{
	color: white ;
	visibility: hidden;
}
header div,
nav {
	display: flex;
	align-items: center;
}

nav{
	align-items: center;
	width: fit-content;
	display: flex;
	justify-content: center;
}

.active:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 2;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    background: red;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 100%;
}


.selected:after{
	background: none repeat scroll 0 0 transparent;
    bottom: 2;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    background: red;
    width: 100%;


}
@media only screen and (max-width: 1202px) {
	header .navBtn {
		visibility: visible;
		display: block;
		opacity: 1;
		margin-right: 1rem;
		
	}
	.companyName{
		color: white ;
		visibility: visible;
		padding-top: 2rem;
	}
	header {
		justify-content: space-between;
		height: 10vh;

	}
	
	.companylogo{
		height: 10vh;
		width: 100px;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
		z-index: -1;

	}

	header .responsiveNav {
		transform: translateY(100vh);
		background-color: black;
		overflow-x: hidden;
		z-index: 11;
	}

	nav .navCloseBtn {
		position: absolute;
		top: 2rem;
		right: 2rem;
		color: white;

	}

	nav a {
		font-size: 1.5rem;
		
		color: white;
	}
}