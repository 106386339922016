.footer {
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  height: 500px;
}

.mapContainer {
  display: flex;
  margin-top: 1rem;
  margin-left: 0.5rem;
  justify-content: space-between;
  width: 98%;
}

.headingfooter{
  font-family: 'Poppins';
  font-size: 1.6rem;
}

.footerhead{
  font-size: 1.5rem;
  font-family: 'Poppins';
  font-weight: 400;
  display: flex;
  text-align: left;
}

.infofooter{
  font-size: 1.5rem;
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  justify-content: center; 
  margin: 1rem;
}
.infofooter h6{
  font-weight: 300;
}

.emails{
font-size: 1.1rem;
font-family: 'Poppins';
margin-top: 2rem;
}
.emails h6{
  font-size: 1.5rem;
  font-weight: 400;
}

.map {
  width: 40%;
  height: 100%;
  padding: 2rem;
  position: relative;
}

.map iframe {
  position: absolute;
  width: 92%;
  height: 100%;
  top: 0;
  left: 0;
}

.loc {
  width: 55%;
}
.loc h2{
  font-weight: 500;
}

.infoloc {
  font-family: 'Poppins';
  font-size: 1rem;
  margin: 4rem 1rem 2rem 0.3rem;
}

.infoloc span {
  margin-left: 10px;
}

.links {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  width: 80%;
  font-size: 1.2rem;
  padding-bottom: 1rem;
}


.infofooter a {
  color: #fff;
  text-decoration: none;
}

.infofooter a:hover {
  text-decoration: underline;
}

.copy {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-family: 'Poppins';
  color: white;
  background-color: #1f1e1e;
  text-align: center;
  margin-top: auto;
}


  @media (max-width: 768px) {
    .footer {
      height: auto; 
    }
  
    .mapContainer {
      flex-direction: column; 
      align-items: center;
      width: 100%; 
      margin: 1rem ; 
    }
  
    .headingfooter {
      font-size: 1.4rem;
    }
  
    .footerhead {
      font-size: 1.3rem; 
    }
  
    .infofooter {
      font-size: 1.2rem;
    }

    .infofooter a {
      color: #fff;
      text-decoration: none;
    }
    
    .infofooter a:hover {
      text-decoration: underline;
    }

    .emails {
      margin-top: 1rem;
    }
  
    .map {
      width: 58%; 
      height: 200px;
      margin-bottom: 1rem; 
      margin-left: 2rem;
    }
  
    .loc {
      width: 80%; 
    }
  
    .infoloc {
      font-size: 1rem; 
      margin: 2rem 0.3rem; 
    }
  
    .links {
      width: 90%;
      font-size: 1rem; 
    }
  
    .copy {
      height: 5vh; 
      width: 90%;
      font-size: 1rem; 
      padding: 1rem;
      margin-top: auto;
    }
  }