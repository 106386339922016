  .mainContainer{
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .productcard {
    padding: 6px;
    background-color:white;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: box-shadow .5s;
    transition: .3s;
    height: 400px;
    width: 100%;
    max-width: 400px;

  }

  
  .imgcontainer {
    width: 100%;
    height: 90%;
    overflow: hidden; 
    background-color: white;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    background-repeat: no-repeat;
    
  }
  .headingcontainer {
 
    height: 30%;
    width: 100%;
    background-color: #CECECE;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    display: flex;
    flex-direction: column;

  }
  
  .productcard:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;    cursor: pointer;

  }

  .head{
    margin-left: 10px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 21px;
    margin-top: 8px;
    height: fit-content;
    color: rgb(43, 42, 42);
  }


.line{
  color: rgb(0, 0, 0);
  margin-left: 10px;
  font-family: 'Poppins';
  font-size: 0.9rem;
}