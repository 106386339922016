.mainContainer{
    margin-top: 0rem;
    width: 100%;
    height: 30vh;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}
.image{
    height: 60%;
    width: 30%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.image:hover{
      cursor: pointer;
      box-shadow: 0 0 11px rgba(33,33,33,.2); 

}