.wrapper{
    margin-bottom: 5rem;
}
.DataContainer{
    display: flex;
    justify-content: center;
    background-color: #F2F2F2;
    height: fit-content;
    width: 100%;
    align-self: center;
}

.left{
    flex-direction: row-reverse;
}
.right{
    flex-direction: row;
}
.textBox{
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 700px;
}
.textBox h1{
    margin-top: 2rem;
    font-family: "Poppins";
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
}
.textBox p{
    display: flex;
    flex-direction: column;
    width: 95%;
    font-size: 0.9rem;
    font-family: "Poppins";
    font-weight: 300;
}
.imageContainer{
    width: 50vw;
    background-color:#F2F2F2;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

}
.arrange{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 842px) {
    .DataContainer{
        display: flex;
        flex-direction: column-reverse;
        width: fit-content;
        height: fit-content;

    }
    .textBox{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding-bottom: 5rem;
        background-color: #F2F2F2;
        min-height: fit-content;
        
    }
    .textBox p{
        width: 80%;
        font-size: 0.9rem;
        font-family: "Poppins";
        font-weight: 300;
    }

    .imageContainer{
        align-self: center;
        width: 98vw;
        height: 40vh;
        background-position: center;
        background-size: cover;
    }
    
    
  }