.modalContainer {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    backdrop-filter: blur(5px); /* Blurred backdrop */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    z-index: 15;
}
.mainContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 80%;
    height: 94%;
    /* min-width: 800px; */
    justify-content: center;
    align-items: center;

}
.mainContainer button{
    background-color: transparent;
    height: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Poppins';
    font-weight: 300;
    border: none;
    position: relative;
    font-size: 20px;
    margin-top:1px;
    margin-right: 2px;
}
.mainContainer button:hover{
   color: rgb(201, 201, 201);
   border-radius: 50%;
   cursor: pointer;

}

.productCard {
    display: flex;
    border-radius: 20px;
    width: 100%;
    height: 90%;
    background-color:#F6F6F6;
}




.productText{
    background-color: #F6F6F6;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: calc(100% - 4rem);
    width: 60%;
    padding: 2rem;
    overflow: hidden;
}
.scroll{
    margin-bottom: 1rem;
    background-color: #F6F6F6;
    height: calc(100% - 7rem);
    overflow-y: scroll;
}
.scroll::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  .scroll::-webkit-scrollbar-thumb {
    background-color: #D9D9D9; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Rounded corners of the thumb */
  }
  
  .scroll::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the scrollbar track */
  }
  

.content{
    background-color:#F6F6F6;
    height: fit-content ;
    font-size: 0.9rem;
    margin-bottom: 100px;
}
.points{
    margin-left: 1rem;
    font-size: 0.9rem;
}
.heading{
    width: fit-content;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 2rem;
    border-bottom:3px solid rgb(204, 203, 203) ;
    cursor: context-menu;
}

@media (max-width: 1000px) {
    .productCard{
        flex-direction: column;
        justify-content: center;
        background-color: white;
        height: 100%;
    }
    .productText{
        width: 100%;
        height: 50vh;
        padding: 0rem;
    }
    .scroll{
        margin-bottom: 0rem;
        height: 100%;
    }
    .heading{
        margin-left: 1rem;
    }
    .content{
        margin-left: 1rem;
        margin-bottom: 100px;

    }
    .mainContainer button{
        height: fit-content;
        background-color: black;
        color: white;
        width: 100%;
        }
}