.mainContainer{
    margin-top: 200px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.redLine{
    width: 90px;
    height: 5px;
    margin-left: 2rem;
    background-color: red;
}

.heading{
    margin-top: -15px;
    text-align: center;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 4rem;
}

.Content{

    font-weight: 300;
    font-size: 1.2rem;
    color: rgb(97, 97, 97);
    text-align: center;
    width: 60%;

}

@media screen and (max-width: 400px) {
    .heading{
        margin-top: -10px;
        font-size: 3rem;
    }
    .Content{
        width: 98%;
    }
}