.parent{
    margin-top: 3rem;
    height: 1920px;
    display: flex;
    position: relative;
    margin-left: 8%;
    margin-right: 8%;
    margin-bottom: 16rem;
}
.mainContainer{
    position:absolute;
    height: 2013px;
    background-color: #FBF9F1;
    width: 100%;
    overflow: hidden;

}
.aboutusCard{
    margin-top: 10%;
    top: 7rem;
    height: fit-content;
    width: 56%;
    background-color: white;
    z-index: 2;
    position: absolute;
    margin-left: 44%;
    position: sticky;
    margin-bottom: 10%;
}
.line{

    border-top: 7px solid #FF0505;
    width: 90px;
    margin-left: 2rem;
    margin-top: 1rem;

}
.heading{
    margin-top: 1rem;
    margin-left: 2rem;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 40px;
}
.content{
    margin: 0rem 2rem 4rem 2rem;
    text-align: justify;
    text-justify: inter-word;
    font-family: "Poppins";


}
.imageContainer{
    margin-left: 16px;
    margin-top :1rem;
    height: 650px;
    width: 70%;
    z-index: 1;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-size: cover;
    background-color: grey;


}
@media screen and (max-width: 868px) {
    .parent {
      display: grid;
      align-items: center;
      height: 400px;
      margin-left: 0;
      margin-right: 0;
  
      display: flex;
      justify-content: center;
    }
    .imageContainer img{
      background-position: center;
      
    }
    .heading{
      font-size: 1rem;
    }
  
    .line{
      border-top: 7px solid #FF0505;
      width: 50px;
      margin-left: 2rem;
      margin-top: 1rem;
    }
  
    .content{
      text-align: left;
      font-size: 0.6rem;
      margin: 1rem;
      height: fit-content;
    }
  
    .mainContainer{
      background-color: #F0F0F0;
      height: 90vh;
      padding: 1rem;
    }
    .parent{
      height: 100vh;
    }
    .heading{
    margin-left: 1rem;
    }
  
    .aboutusCard {
      margin-left: 10px;
      margin-right: 10px;
      width: 90%; 
      height: fit-content;
      
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  
    .imageContainer {
      justify-content: center;
      height: 50vh;
      width: 95%;
      background-position: center;
    }
  
  }