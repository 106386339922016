.mainContainer{
    height: fit-content;
    width: 100%;
    background: #d6d6d6;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1 0 45%;
    border-bottom: 1px solid white;

}

.btn {
    width: 70px;
    height: 70px;
    margin: 20px;
    outline: none;
    border: none;
    background: #d6d6d6;
    box-shadow: 5px 5px 10px #b6a9a9, -5px -5px 10px #ffffff;
    border-radius: 50%;
    transition: 0.2;
  }

  .btn:hover {
    cursor: pointer;
    animation-name: pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  @keyframes pulse {
    from {
      transform: scale(1);
    }
    50% {
      transform: scale(1.21);
    }
    to {
      transform: scale(1);
    }
  }
  