.mainContainer{
    height: 100%;
    width: 60%;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.redLine{
    background-color: red;
    height: 5px;
    width: 90px;
    margin-top: 2rem;

}
.heading{
    margin-top: -8px;
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 2rem;
}


.valueWrapper{
    height: 80%;
    width: 100%;
}


@media (max-width: 842px) {   
    
    .mainContainer{

    width: 100%;
    }
    .heading{
       
        margin-bottom: 0px;
    }
    
}
