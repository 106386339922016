.newscontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.newscontent{
  font-size: 2.2rem;
  margin: 1rem;
  font-family: 'Poppins';
}

.carousel {
  display: flex;
  overflow: hidden;
  height: fit-content;
  margin-top: 1rem;
  padding: 1rem; 
}

.slides {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  animation: moveRow 200s infinite linear;
}

.slides:hover{
  animation-play-state: paused;
}

.slide {
  display: flex;
  flex-direction: row;
  width: 900px;
  height: 350px;
  margin-top: 1rem;
  margin-right: 3rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.4s;
}

.slide:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.imageContainer{
  width: 500px;
  height: 300px;
  background-color: white;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 7px;
  margin-bottom: 30px;
}

.line{
  background-color: red;
  height: 5px;
  width: 100px;   
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  width: fit-content;
  height: 350px;
  background-color: #F0F0F0;
}

.title {
  font-size: 1rem;
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-weight: 400;
}

.description {
  font-size: 1.01rem;
  width: 95%;
  font-family: 'Poppins'; 
}


.slideImage {
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
}

@keyframes moveRow {
  0% {
      transform: translate3d(0, 0, 0)
  }

  50% {
      transform: translate3d(-83%, 0, 0)
  }

  100% {
      transform: translate3d(0, 0, 0)
  }
}

@media (max-width: 768px) {
  .slide {
    flex-direction: column;
    width: 400px;
    height: auto;
    margin-top: 1rem;
    margin-right: 3rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.4s;
  }

  .imageContainer{
    width: 370px;
    height: 270px;
    background-color: white;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  

  .content {
    width: 100%;
    height: fit-content;
    font-size: 0.6rem;
    background-color: #F0F0F0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aquamarine; */
  }

  .title {
    font-size: 1rem;
  }

  .description {
    font-size: 0.7rem;
    width: 95%;
    /* background-color: red; */
  }

  .slideImage {
    width:100%;
    height: 100%;
    padding-bottom: 0;
  }
}